import { useEffect, useMemo, useState } from 'react'

function useIsInViewport(reference) {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  )

  useEffect(() => {
    observer.observe(reference.current)

    return () => {
      observer.disconnect()
    }
  }, [reference, observer])

  return isIntersecting
}

export default useIsInViewport
