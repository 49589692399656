import React, { useRef } from 'react'

import { HOW_WORKS_BACK_IMAGES } from '../../../constants/icons'
import useIsInViewport from '../../../hooks/useIsInViewPort'

import StepPin from './StepPin'

const Step = ({ stepNumber, windowImage, circle, title, text, line, reverse }) => {
  const reference = useRef(null)
  const isInViewPort = useIsInViewport(reference)

  return (
    <div
      ref={reference}
      className={`relative mx-auto md:mb-16 md:flex md:justify-center ${
        reverse ? 'md:flex-row-reverse md:pr-[5%]' : 'md:pl-[3%]'
      }`}
    >
      <img
        src={circle}
        alt="circulo"
        className={`absolute top-0 ${
          reverse ? 'left-0' : 'right-0'
        } z-0 hidden w-80 ${isInViewPort ? 'animate-grow-appear': 'opacity-0'} md:inline`}
      />
      <div className={`relative mt-16 mb-8 flex md:w-96 ${isInViewPort ? 'animate-side-appear' : 'opacity-0'}`}>
        <div className="w-fit">
          <StepPin step={stepNumber} />
        </div>
        <div className="w-3/5 text-pinflag-dark">
          <div className="mb-4 font-poppins text-xl font-semibold">{title}</div>
          <div className="text-sm">{text}</div>
        </div>
      </div>
      <img src={windowImage} alt="checkout" className={`z-10 m-auto w-4/5 md:m-0 md:w-1/2 ${isInViewPort ? 'animate-side-appear' : 'opacity-0'}`} />
      {line && (
        <img
          src={reverse ? HOW_WORKS_BACK_IMAGES.line1 : HOW_WORKS_BACK_IMAGES.line2}
          alt="line"
          className={`absolute top-[75%] left-[30%] z-0 hidden h-[60%] w-[30%] md:inline xl:top-[70%] xl:h-[50%] ${isInViewPort ? 'animate-side-appear' : 'opacity-0'}`}
        />
      )}
    </div>
  )
}

export default Step
