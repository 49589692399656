import React from 'react'
import { Link } from 'react-router-dom'

import ROUTES from '../../constants/routes'
import { close, pinflagLogo } from '../../constants/icons'
import DropDownButtonMobile from './DropDownButtonMobile'
import { NAVBAR_SERVICES } from '../../constants/navbar'

const MobileMenu = ({ closeMenu, closeAndScroll }) => (
  <div className="absolute top-0 left-0 flex w-full flex-col items-end justify-center bg-white shadow-xl">
    <div className="flex w-full items-center justify-between">
      <img src={pinflagLogo} alt="logo" className="m-4 w-16" />
      <button onClick={closeMenu} type="button">
        <img src={close} alt="close" className="m-4 w-4" />
      </button>
    </div>
    <div className="flex w-full flex-col text-center">
      <Link to={ROUTES.HOME} className="py-4 hover:bg-pinflag-200" onClick={closeAndScroll}>
        Inicio
      </Link>
      <DropDownButtonMobile text="Servicios">
        <>
          {NAVBAR_SERVICES.map((service, index) => (
            <Link
              key={service}
              to={`${ROUTES.SERVICES}?step=${index}`}
              name={service.name}
              className="py-4 hover:bg-pinflag-200"
              onClick={closeAndScroll}
            >
              {service.name}
            </Link>
          ))}
        </>
      </DropDownButtonMobile>
      {/* <Link to={ROUTES.OUR_TEAM} className="py-4 hover:bg-pinflag-200" onClick={closeAndScroll}>
        Nosotros
      </Link> */}
      <button
        onClick={() => window.open('https://www.linkedin.com/newsletters/6947982808162136064/')}
        className="py-4 hover:bg-pinflag-200"
        type="button"
      >
        Blog
      </button>
    </div>
  </div>
)

export default MobileMenu
