import { ASSETS_URL } from './icons'

const CMS_LOGOS = [
  `${ASSETS_URL}/home/cms/vtex.png`,
  `${ASSETS_URL}/home/cms/shopify.png`,
  `${ASSETS_URL}/home/cms/woocommerce.png`,
  `${ASSETS_URL}/home/cms/magento.png`
]

export default CMS_LOGOS
