import React from 'react'
import GALLERY_PHOTOS from '../../../constants/ourTeam/galleryPhotos'


const TeamGallery = () => (
  <div className="">
    <div className="mb-8 w-screen animate-grow-appear pt-16 text-center font-poppins text-xl font-bold leading-10 text-pinflag-dark md:text-2xl lg:pt-20 lg:text-3xl lg:leading-[3rem] xl:pt-24 xl:text-4xl xl:leading-[3.5rem]">
      Trabajamos...
      <div className="block leading-10 text-pinflag">¡Pero también lo pasamos bien!</div>
    </div>
    <div className="bg-home-bottom bg-cover bg-top bg-no-repeat">
      <div className=" mx-10 flex flex-wrap justify-center gap-10 pt-16 pb-28 sm:mx-24">
        {GALLERY_PHOTOS.map(photo => (
          <img src={photo} alt="foto team" className="w-auto rounded-3xl grayscale transition-all hover:scale-110 hover:grayscale-0 sm:h-[37vh]" />
        ))}
      </div>
    </div>
  </div>
)

export default TeamGallery
