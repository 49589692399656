import React from 'react'

import { pin } from '../../../constants/icons'

const StepPin = ({ step }) => (
  <div className="relative mx-8 w-max">
    <img src={pin} alt="pin" className="w-10" />
    <div className="absolute top-[0.28em] left-[.75rem] w-4 text-center text-xl font-medium text-pinflag-dark">{step}</div>
  </div>
)

export default StepPin
