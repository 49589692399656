import React from 'react'

import { TEAM_ICONS } from '../../../constants/icons'

const Header = () => (
  <div className="h-[40vh] animate-slow-grow-appear bg-our-team-top bg-cover bg-bottom bg-no-repeat md:mb-[30vh] md:h-[50vh] md:bg-contain md:bg-top lg:h-[60vh]">
    <img src={TEAM_ICONS.ourTeam} alt="nuestro equipo" className="mx-auto w-1/2 animate-slow-grow-appear pt-8 sm:w-1/3 md:w-1/4 lg:pt-16" />
    <div className="mb-8 w-screen animate-grow-appear pt-8 text-center font-poppins text-xl font-bold leading-10 text-pinflag-dark md:text-2xl lg:pt-10 lg:text-3xl lg:leading-[3rem] xl:pt-6 xl:text-4xl xl:leading-[3.5rem]">
      Conoce a cada uno
      <div className="block leading-10 text-pinflag">de nosotros</div>
    </div>
    <a href="#team" className="animate-slow-grow-appear"><img src={TEAM_ICONS.arrow} alt="flecha" className="mx-auto mt-4 w-12 animate-bounce" /></a>
  </div>
)

export default Header
