import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { cellPhone } from '../../constants/icons'

const Hero = () => {
  const [openVideo, setOpenVideo] = useState(false)

  return (
    <div className="h-[100vh] w-full items-center justify-evenly bg-home-top bg-cover bg-bottom bg-no-repeat md:flex md:h-[90vh] lg:bg-home-top-xl">
      <div className="flex animate-side-appear flex-col items-start justify-between p-8 md:ml-8 md:mb-24 lg:ml-14 lg:mb-36 lg:w-1/3">
        <div className="my-4 font-poppins text-xl font-bold leading-10 text-white md:text-2xl lg:text-3xl lg:leading-[3rem] xl:text-4xl xl:leading-[3.5rem]">
          Múltiples opciones de despacho en
          <div className="block leading-10 text-pinflag-dark">tu e-commerce</div>
        </div>
        <p className="my-4 text-sm text-white lg:text-base">
          Crece aún más con nosotros y mejora tu propuesta de envíos y omnicanalidad con nuestra
          tecnología que te conecta con puntos pick-up, tiendas o a domicilio.
        </p>
        <button
          onClick={() => {
            window.scrollTo(0, 0)
            setOpenVideo(true)
          }}
          type="button"
          className="my-4 rounded-3xl bg-pinflag-dark py-2 px-4 font-poppins font-medium text-white hover:opacity-70"
        >
          Ver video
        </button>
      </div>
      {openVideo && (
        <div
          id="video-handler"
          onClick={() => setOpenVideo(false)}
          className="absolute top-0 left-0 z-20 flex h-full w-full items-center justify-center bg-black/70"
          role="button"
          tabIndex="0"
        >
          <div>
            <ReactPlayer
              url="https://youtu.be/61m_0Fevn-k"
              className=""
              controls
              width="70vw"
              height="70vh"
            />
          </div>
        </div>
      )}
      <img className="m-auto w-4/5 animate-slow-grow-appear sm:w-2/3 md:my-8 md:w-1/2" src={cellPhone} alt="video" />
    </div>
  )
}

export default Hero
