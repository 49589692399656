import React, { useRef } from 'react'

import { HOW_WORKS_BACK_IMAGES } from '../../constants/icons'
import useIsInViewport from '../../hooks/useIsInViewPort'

const StepServices = ({ windowImage, title1, title2, text, specialLine, line, reverse }) => {
  const reference = useRef(null)
  const isInViewPort = useIsInViewport(reference)

  return (
    <div
      ref={reference}
      className={`relative z-10 mx-auto md:mb-16 md:flex md:justify-center ${
        reverse ? 'md:pr-[5%]' : 'md:pl-[3%]'
      } ${isInViewPort ? 'animate-side-appear' : 'opacity-0'}`}
    >
      <div
        className={`flex flex-col items-center justify-center md:flex-row ${
          reverse ? 'md:flex-row-reverse xl:space-x-reverse' : ''
        } xl:space-x-24`}
      >
        <div
          className={`relative mt-16 mb-8 flex h-fit md:w-96 ${
            reverse ? 'justify-center md:justify-end' : 'justify-center md:justify-start'
          }`}
        >
          <div className="w-4/5 text-pinflag-dark">
            <div className="font-poppins text-xl font-semibold">{title1}</div>
            <div className="mb-4 font-poppins text-xl font-semibold text-pinflag">{title2}</div>
            <div className="text-sm">{text}</div>
          </div>
        </div>
        <img
          src={windowImage}
          alt="checkout"
          className="z-10 m-auto max-h-xl w-4/6 md:m-0 md:w-2/6"
        />
      </div>
      {line && (
        <img
          src={reverse ? HOW_WORKS_BACK_IMAGES.line1 : HOW_WORKS_BACK_IMAGES.line2}
          alt="line"
          className={`absolute top-[74%] left-[32%] z-0 hidden w-[30%] md:inline xl:top-[73%]  ${
            specialLine || 'h-[60%] xl:h-[50%]'
          } ${reverse && 'left-[32%]'}`}
        />
      )}
    </div>
  )
}

export default StepServices
