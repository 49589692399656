import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import ServiceExplanation from '../components/Services/ServiceExplanation'
import ServicesHero from '../components/Services/ServicesHero'
import TabBar from '../components/Services/TabBar'
import SERVICES_HERO_CONTENT from '../constants/services/servicesHero'
import SERVICES_STEPS, { BACKGROUND_CIRCLES } from '../constants/services/servicesSteps'

const Services = () => {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const [step, setStep] = useState(0)

  useEffect(() => {
    const selectedStep = parseInt(query.get('step'), 10) || 0
    setStep(selectedStep)
  }, [query])

  return (
    <div>
      <ServicesHero content={SERVICES_HERO_CONTENT[step]} step={step} />
      <TabBar step={step} setStep={setStep} />
      <ServiceExplanation
        content={SERVICES_STEPS[step]}
        circles={BACKGROUND_CIRCLES[step]}
        key={step}
      />
    </div>
  )
}

export default Services
