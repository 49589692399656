import React, { useState } from 'react'

import { downArrow } from '../../../constants/icons'

const DropDownButton = ({ active, text, children }) => {
  const [dropDownOpen, setDropDownOpen] = useState(false)
  return (
    <div className="mx-6 my-auto flex cursor-pointer items-center justify-center gap-2">
      <div
        onMouseEnter={() => setDropDownOpen(true)}
        onMouseLeave={() => setDropDownOpen(false)}
        role="button"
        tabIndex="0"
        className="relative flex"
      >
        <span className={active ? 'text-pinflag' : 'text-dark-gray'}>{text}</span>
        <img className={`mx-2 w-2 transition ${dropDownOpen && 'rotate-180'}`} src={downArrow} alt={text} />
        {dropDownOpen && (
          <div className="absolute top-4 left-0 flex w-fit flex-col rounded-lg bg-white py-4 pl-10 pr-16 shadow-xl">
            {children}
          </div>
        )}
      </div>
    </div>
  )
}

export default DropDownButton
