import React from 'react'

import { SALE_ORDER_COUNT } from '../../../constants/statistics'
import Counter from './Counter'

const ClientsCounter = () => (
  <div className="whitespace-nowrap bg-pinflag p-16 text-center font-poppins font-bold text-white md:text-xl lg:text-3xl">
    <div className="m-auto">
      Hoy contamos con más de
      <span className="block lg:inline-flex"><Counter end={SALE_ORDER_COUNT} reference="sale-counter" /></span>
      pedidos administrados
    </div>
  </div>
)

export default ClientsCounter
