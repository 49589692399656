import React from 'react'
import { Link } from 'react-router-dom'

const NavButton = ({ route, active, children }) => (
  <Link
    to={route}
    className={`mx-6 my-auto hover:opacity-70 ${active ? 'text-pinflag' : 'text-dark-gray'}`}
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  >
    {children}
  </Link>
)

export default NavButton
