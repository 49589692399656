import React, { useCallback } from 'react'

import CountUp from 'react-countup'

const Counter = ({ end }) => {
  const generateRestingCharacters = (resting) => {
    const restingCharacters = []
    for (let index = 0; index < resting; index += 1) {
      restingCharacters.push(
        '<span style="background-color: white; margin-left: 0.25rem; border-radius: 5px; padding: 0 0.5rem;">0</span>'
      )
    }
    return restingCharacters.join('')
  }

  const formatter = useCallback(
    (number) => {
      const characters = number.toString().split('')
      const formattedNumber = characters
        .map(
          (character) =>
            `
              <span 
                style="background-color: white; 
                margin-left: 0.25rem; 
                border-radius: 5px; 
                width: 2rem;
                display: flex;"
              ><span style="margin: auto; line-height: 1.5;">${character}</span></span>
            `
        )
        .join('')
      const resting = end.toString().split('').length - characters.length
      const restingCharacters = generateRestingCharacters(resting)

      return `${restingCharacters}${formattedNumber}`
    },
    [end]
  )

  return (
    <span className="mx-2 font-normal leading-loose text-pinflag-dark">
      <CountUp
        style={{ display: 'flex' }}
        start={end / 2}
        end={end}
        formattingFn={formatter}
        enableScrollSpy
        scrollSpyOnce
        scrollSpyDelay={0}
      />
    </span>
  )
}

export default Counter
