import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import ROUTES from '../../constants/routes'
import { bars, pinflagLogo } from '../../constants/icons'
import { NAVBAR_SERVICES } from '../../constants/navbar'

import NavButton from './NavButton'
import DropDownButton from './DropDownButton/DropDownButton'
import MobileMenu from './MobileMenu'
import DropDownItem from './DropDownButton/DropDownItem'

const NavBar = () => {
  const [mobileMenuStatus, setMobileMenuStatus] = useState(false)

  const currentPath = useLocation().pathname

  return (
    <div className="sticky top-0 z-30 flex w-full items-center justify-between bg-white py-0.5 px-1 font-poppins text-sm font-medium text-pinflag-dark shadow-md">
      <div className="flex w-2/4 items-center justify-items-start md:pl-16">
        <img src={pinflagLogo} alt="logo" className="m-2 w-20" />
        <div className="hidden flex-col lg:flex lg:flex-row">
          <NavButton route={ROUTES.HOME} active={currentPath === ROUTES.HOME}>
            Inicio
          </NavButton>
          <DropDownButton text="Servicios" active={currentPath === ROUTES.SERVICES}>
            <>
              {NAVBAR_SERVICES.map((service, index) => (
                <DropDownItem
                  key={service}
                  pathname={ROUTES.SERVICES}
                  search={`?step=${index}`}
                  name={service.name}
                  icon={service.icon}
                />
              ))}
            </>
          </DropDownButton>
          <NavButton route={ROUTES.OUR_TEAM} active={currentPath === ROUTES.OUR_TEAM}>
            Nosotros
          </NavButton>
          <button
            onClick={() => window.open('https://www.linkedin.com/newsletters/6947982808162136064/')}
            className="mx-6 my-auto text-dark-gray hover:opacity-70"
            type="button"
          >
            Blog
          </button>
        </div>
      </div>
      <a href="https://admin.pinflag.cl/" className="mr-4 ml-auto hover:opacity-70">
        Entrar
      </a>
      <button
        onClick={() => window.open('https://calendly.com/nicolas-raimann/demo-pinflag')}
        className="mx-4 whitespace-nowrap rounded-3xl bg-pinflag px-4 py-2 text-xs text-white hover:opacity-70 sm:text-sm md:mr-16 md:font-semibold"
        type="button"
      >
        Agendar demo
      </button>
      <div
        onMouseEnter={() => setMobileMenuStatus(true)}
        onMouseLeave={() => setMobileMenuStatus(false)}
        className="mx-2 w-6 lg:hidden"
        role="button"
        tabIndex="0"
      >
        {mobileMenuStatus && (
          <MobileMenu
            closeMenu={() => setMobileMenuStatus(false)}
            closeAndScroll={() => {
              setMobileMenuStatus(false)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
        )}
        <img src={bars} alt="dropdown" />
      </div>
    </div>
  )
}

export default NavBar
