import React from 'react'

import TEAM from '../../../constants/ourTeam/team'

import ProfileCard from './ProfileCard'

const Team = () => (
  <div className="m-auto mt-44 flex max-w-[80%] flex-wrap justify-center lg:mt-20">
    {TEAM.map((coworker) => (
      <ProfileCard
        image={coworker.image}
        name={coworker.name}
        position={coworker.position}
        linkedin={coworker.linkedin}
        description={coworker.description}
        activeShowDetails={coworker.showDetails}
        highlight={coworker.freakFact}
        quote={coworker.typicalQuote}
      />
    ))}
  </div>
)

export default Team
