import React from 'react'

import Header from '../../components/Us/OurTeam/Header'
import Team from '../../components/Us/OurTeam/Team'
import TeamGallery from '../../components/Us/OurTeam/TeamGallery'

const OurTeam = () => (
  <div className="overflow-x-hidden scroll-smooth">
    <Header />
    <div className="flex xl:pt-10" id="team">
      <Team />
    </div>
    <div className="mb-16 flex">
      <button
        onClick={() => window.open('https://pinflag.notion.site/Trabaja-con-nosotros-753eedd419154c60a6beaaf4db5702c7')}
        className="m-auto w-fit rounded-full bg-pinflag-dark py-3 px-5 font-poppins font-semibold text-white hover:opacity-70"
        type="button"
      >
        ¡Postula aquí!
      </button>
    </div>
    <TeamGallery />
  </div>
)

export default OurTeam
